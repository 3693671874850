<template>
	<div :class="themeClass" class="max-width1110 margin-t-20">
		<div class="flex-row margin-b-5 font-color-999">
			您的位置：
			<breadcrumb></breadcrumb>
		</div>
		<div class="flex-colum">
			<div class="flex-row top-box">
				<div class="box-left margin-r-20">
					<div class="outline-imgs">
						<div class="outline-img">
							<piczoom :url="showPicUrl" :scale="3" :show1="1"></piczoom>
						</div>
						<div class="outline-piclist">
							<div class="left-arrow margin-t-20" v-if="picurl.src.length > 1" @click="leftImg">◀</div>
							<div class="right-arrow margin-t-20" v-if="picurl.src.length > 1" @click="rightImg">▶</div>
							<div class="piclist-box" ref="piclist">
								<div class="piclist-img" v-for="(value,index) in picurl.src" :key="index" :class="{'active':showPicUrl == value.url}"
								 @click="imgClick(value.url,index)">
									<img :src="value.url" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="box-right flex-colum">
					<div class="flex-row margin-b-5">
						<div class="font-size18 font-weight700" style="line-height:29px;">{{listData.fGoodsFullName}}</div>
					</div>
					<div class="commodityInformation-box padding-13 font-size13 bg-FFFAF9 font-color-666">
						<div class="flex-row-space-between-center margin-b-10" v-if="listData.fActivityID != -1">
							<div class="flex-row-space-between-center">
								<div class="margin-r-25 font-color-999">
									金币
								</div>
								<span class="margin-r-10 font-size25 font-weight700 " style="color: #FA2C60;">
									{{listData.fActivityPrice}}
								</span>
								<span class="margin-r-10 font-size20" style="text-decoration: line-through;">
									{{listData.fGoodsPrice}}
								</span>

							</div>

						</div>
						<div class="flex-row-space-between-center margin-b-10" v-else>
							<div class="flex-row-space-between-center">
								<div class="margin-r-25 font-color-999">
									金币
								</div>
								<span class="margin-r-10 font-size25 font-weight700 " style="color: #FA2C60;">
									{{listData.fGoodsPrice}}
								</span>

							</div>

						</div>
						<!-- <div class="margin-b-10 flex-row">
							<div class="margin-r-25 font-color-999">
								优惠
							</div>
							{{listData.integral}}
						</div> -->
						<div class="margin-b-10 flex-row">
							<div class="margin-r-25 font-color-999">
								运费
							</div>
							商家承担
							<!-- 	{{listData.integral}} -->
						</div>
					</div>
					<div class="padding-t-13 padding-lr-13 font-color-666 commodityInformation-box">

						<div class="flex-colum font-size13">
							<div class="flex-row margin-b-10">
								<div class="font-color-999 padding-tb-5 tatle-box">
									规格
								</div>
								<div class="factory-box padding-tb-5 border-F2F2F2 text-center pointer-color-theme factory-hover" @click="packingClick(listData.fRemark)">{{listData.fRemark}}</div>
								<!-- 								<div class="margin-r-15 font-color-000" v-for="(item,index) of packingPopupData" :key="index">
									<div class="factory-box padding-tb-5 border-F2F2F2 text-center pointer-color-theme factory-hover" :class="item.checked?'bg-F8F9FE border-theme color-theme':''"
									 @click="packingClick(item.factory)">{{item.factory}}</div>
								</div> -->
							</div>
							<div class="flex-row margin-b-30">
								<div class="font-color-999 padding-tb-5 tatle-box">
									数量
								</div>
								<div class="flex-row-wrap width-fill">
									<el-input-number v-model="fPlanAmount" @change="handleChange" :min="listData.goodsSaleAttribute.fMinSaleAmount" :max="99999"
									 :precision="listData.goodsSaleAttribute.fPrecision" :step="listData.goodsSaleAttribute.fAdjustmentRange"
									 label="描述文字" size="mini" @focus="getInputFocus($event)"></el-input-number>
								</div>
							</div>
							<div class="flex-row margin-b-50">
								<div class="btn-box background-color-theme radius30 text-center margin-r-10 pointer" @click="addToCar">
									加入购物车
								</div>
								<div class="btn-box radius30 text-center margin-r-10 bg-FF9900 font-color-fff pointer" @click="toFillOrderGoldMall">
									立即兑换
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bottom-box font-size13 flex-row">
				<div class="b-box-right">
					<el-tabs v-model="activeName" type="border-card" @tab-click="handleClick" v-for="(item,index) in detailList" :key="index">
						<el-tab-pane :label="item.fGoodsDetailClass" :name="(index+1)+''">
							<div class="border-F2F2F2 padding-20">
								<RichTextStyle :html="item.fContent+``" :styleRule="rule" />
							</div>
						</el-tab-pane>
						<!-- 							<el-tab-pane label="商品详情" name="first">
								<div class="border-F2F2F2 padding-20">
									<RichTextStyle v-if="content != null" :html="content" :styleRule="rule" />
								</div>
							</el-tab-pane>
							<el-tab-pane label="规格参数" name="second">
								<div class="border-F2F2F2 padding-20">
									<RichTextStyle v-if="content != null" :html="content" :styleRule="rule" />
								</div>
							</el-tab-pane>
							<el-tab-pane label="使用范围" name="third">
								<div class="border-F2F2F2 padding-20">
									<RichTextStyle v-if="content != null" :html="content" :styleRule="rule" />
								</div>
							</el-tab-pane>
							<el-tab-pane label="售后保障" name="fourth">
								<div class="border-F2F2F2 padding-20">
									<RichTextStyle v-if="content != null" :html="content" :styleRule="rule" />
								</div>
							</el-tab-pane> -->

					</el-tabs>
				</div>
			</div>
		</div>

		<el-dialog v-dialogDrag title="议价申请" :visible.sync="showModel" width="30%" :before-close="handleClose"
		 :close-on-click-modal="false" :close-on-press-escape="false">
			<div ref="dialogBox" class="dialogScroll">
				<el-form ref="form" :rules="rules" :model="form" :label-width="formLabelWidth" label-position="left">
					<el-form-item label="询价单号">
						<el-input v-model="form.danhao" placeholder="请填写询价单号" />
					</el-form-item>
					<el-form-item label="价格开始日期">
						<el-date-picker type="date" placeholder="请选择价格开始日期" v-model="form.startDate" style="width: 100%;"></el-date-picker>
					</el-form-item>
					<el-form-item label="使用地点" prop="didian">
						<el-input v-model="form.didian" placeholder="请填写使用地点" />
					</el-form-item>
					<el-form-item label="原因" prop="yuanyin">
						<el-input v-model="form.yuanyin" placeholder="请填写原因" />
					</el-form-item>
					<el-form-item label="备注" prop="fRemark">
						<el-input v-model="form.fRemark" placeholder="请填写备注" />
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleClose">取 消</el-button>
				<el-button type="primary" @click="submitForm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import breadcrumb from '@/components/breadcrumb.vue'
	import countDowm from '@/components/countDown/countDown.vue'
	import RichTextStyle from "@/components/richtext/RichTextStyle";
	import pagination from '@/components/pagination/pagination.vue'
	import selectArea from '@/components/selectArea/selectArea.vue';
	import piczoom from '@/components/vue-piczoom/'
	export default {
		components: {
			breadcrumb,
			countDowm,
			RichTextStyle,
			pagination,
			selectArea,
			piczoom
		},
		data() {
			return {
				showPicUrl: "",
				picurl: {
					src: []
				},
				showPicInx: 0, // 当前选中商品推按下标
				listData: {
					goodsSaleAttribute: ""

				},
				countDownShow: true,
				fPlanAmount: 1, //计数器默认值
				followShow: false, //是否关注
				value1: 2,
				shopGoodsData: [{
						img: require('@/assets/imgs/GoldCoinMall/boliguo.png'),
						title: '天山牌普硅42.5叶城天山袋装天山牌普硅天山牌普硅42.5（袋装）',
						jiage: 1068
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/boliguo.png'),
						title: '天山牌普硅42.5叶城天山袋装天山牌普硅天山牌普硅42.5（袋装）',
						jiage: 1068
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/boliguo.png'),
						title: '天山牌普硅42.5叶城天山袋装天山牌普硅天山牌普硅42.5（袋装）',
						jiage: 1068
					},
				],
				activeName: "1", //选中哪个
				content: ``,
				rule: [], //富文本规则
				thumbActive: false, //评价是否点赞
				total: 1000, //总条数
				pagesize: 10, //每页显示条目个数
				currentPage: 1, //当前页
				showModel: false, //打开议价申请
				form: {
					danhao: '',
					startDate: '',
					didian: '',
					yuanyin: '',
					fRemark: ''
				},
				rules: {
					didian: [{
						required: true,
						message: '地点不能为空',
						trigger: 'blur'
					}],
				},
				formLabelWidth: '100px', //label宽
				area: null, //区域id
				evaluateBtnData: [{
						name: '全部',
						checked: true
					},
					{
						name: '好评60+',
						checked: false
					},
					{
						name: '中评',
						checked: false
					},
					{
						name: '差评',
						checked: false
					},
					{
						name: '差评',
						checked: false
					},
					{
						name: '差评',
						checked: false
					}
				],
				detailList: []
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {
			this.$refs.piclist.style.left = 48 + "px";
			this.getList();
			this.getgoodsDetailList()
		},
		methods: {
			getList() {
				this.ApiRequestPost(
					"api/mall/ebsale/goods-point-price/get-gold-coin-goods-baseinfo", [this.$route.query.id]
				).then(
					(result) => {
						console.log(result);
						this.showPicUrl = result.obj[0].fPicturePath;
						this.fPlanAmount = result.obj[0].goodsSaleAttribute.fMinSaleAmount
						this.listData = result.obj[0];
						this.picurl.src.push({
							url: result.obj[0].fPicturePath
						})
						if (result.obj[0].goodsPictures.length > 0) {
							for (let item of result.obj[0].goodsPictures) {
								if (item.fPicturePath != null && item.fPicturePath != undefined && item.fPicturePath != "") {
									this.picurl.src.push({
										url: item.fPicturePath
									})
								}
							}
						}
					},
					(error) => {
						//   alert(JSON.stringify(error));
					}
				)
			},
			getgoodsDetailList() {
				this.ApiRequestPost(
					"api/mall/goods/goods-detail/get-by-goodsid-list", {
						goodsID: this.$route.query.id,
						fAppType: 1
					}
				).then(
					(result) => {
						this.detailList = result.obj
						console.log(result.obj, " result.obj345678")
						// this.showPicUrl = result.obj.fPicturePath;
						// this.picurl.src.push({
						// 	url: result.obj.fPicturePath
						// })
						// if (result.obj.goodsPictures.length > 0) {
						// 	for (let item of result.obj.goodsPictures) {
						// 		this.picurl.src.push({
						// 			url: item.fPicturePath
						// 		})
						// 	}
						// }
						// this.listData = result.obj;
						// console.log(this.showPicUrl)
						// console.log(this.picurl.src)
						// console.log(this.listData, "商品的数据")

					},
					(error) => {
						//   alert(JSON.stringify(error));
					}
				)
			},
			//加入购物车提示
			getTips() {
				const message = this.$message({
					dangerouslyUseHTMLString: true,
					type: 'success',
					showClose: true,
					duration: 3000,
					customClass: 'iconshopcar',
					message: `<span style="font-size:16px;color:#10C578;font-weight:bold">成功加入购物车！</span>
										  <p style="margin-top:20px;color:#999999">您可以<a  class="aselc" href="#/goldMall/GoldShoppingCart"  style="color:dodgerblue;cursor: pointer;">去购物车结算</a>，或<a class="aselce" href="#/goldMall/goldGoods"  style="color:dodgerblue;cursor: pointer;">返回列表</a></p>`
				});
				message.$el.querySelector('.aselc').onclick = () => {
					message.close();
				};
				message.$el.querySelector('.aselce').onclick = () => {
					message.close();
				};
			},
			// 加入购物车
			addToCar() {
				this.ApiRequestPostNOMess(
					"api/mall/ebsale/shopping-cart/create-gold-coin-shopping-cart", {
						fGoodsPrice: this.listData.fGoodsPrice,
						fAmount: this.fPlanAmount,
						fGoodsID: this.$route.query.id,
					}
				).then(
					(result) => {
						this.getTips();
					},
					(error) => {

					}
				)
			},

			//立即兑换跳转填写订单页面
			toFillOrderGoldMall() {
				let obj = [];
				let data = {
					fGoodsID: this.$route.query.id,
					fAmount: this.fPlanAmount
				};
				obj.push(data);
				////console.log('obj', obj);
				this.$store.commit("changeCommonData", obj);
				this.$router.push({
					name: 'FillOrderGoldMall',
					query: {
						id: 1
					}
				})
			},

			//小图左边三角点击事件
			leftImg() {
				if (this.showPicInx > 0 && this.showPicInx <= this.picurl.src.length) {
					this.showPicInx = this.showPicInx - 1;
					this.showPicUrl = this.picurl.src[this.showPicInx].url;
					if (
						this.showPicInx == 0 ||
						this.showPicInx == 1 ||
						this.showPicInx == 2 ||
						this.showPicInx == 3
					) {
						this.$refs.piclist.style.left = 38 + "px";
					} else {
						this.$refs.piclist.style.left =
							"-" + 125 * (this.showPicInx - 3) + "px";
					}
				}
			},
			//小图右边三角点击事件
			rightImg() {
				if (this.showPicInx >= 0 && this.showPicInx < this.picurl.src.length - 1) {
					this.showPicInx = this.showPicInx + 1;
					this.showPicUrl = this.picurl.src[this.showPicInx].url;
					if (this.showPicInx == 0 || this.showPicInx == 1 || this.showPicInx == 2 || this.showPicInx == 3) {
						this.$refs.piclist.style.left = 20 + "px";
					} else {
						console.log('11');
						this.$refs.piclist.style.left =
							"-" + 130 * (this.showPicInx - 3) + "px";
					}
				}
			},
			//图片点击事件
			imgClick(val, index) {
				this.showPicUrl = val;
				this.showPicInx = index;
			},
			//倒计时结束事件
			countDownEnd(val, index) {
				this.countDownShow = false;
			},
			//生产厂点击事件
			// factoryClick(val) {
			// 	for (let item of this.selectedPopupData) {
			// 		if (item.factory == val) {
			// 			item.checked = true;
			// 		} else {
			// 			item.checked = false;
			// 		}
			// 	}
			// },
			//包装点击事件
			packingClick(val) {
				console.log(val, "567890-=")
				// for (let item of this.packingPopupData) {
				// 	if (item.factory == val) {
				// 		item.checked = true;
				// 	} else {
				// 		item.checked = false;
				// 	}
				// }
			},
			//计数器事件
			handleChange(value) {
				console.log(value);
				if (!value) {
					this.fPlanAmount = 0;
					return
				}
				this.fPlanAmount = value;
			},
			//计数器获得焦点全选内容
			getInputFocus(event) {
				event.currentTarget.select();
			},
			//关注事件
			followClick() {
				this.followShow = !this.followShow;
			},
			//tab点击事件
			handleClick(tab, event) {
				console.log(tab, event);
			},
			//选择评价条件事件
			evaluateBtnClick(val) {
				for (let item of this.evaluateBtnData) {
					if (val == item.name) {
						item.checked = true;
					} else {
						item.checked = false;
					}
				}
			},
			//评价点赞事件
			thumbClick() {
				this.thumbActive = !this.thumbActive;
			},
			//pageSize（每页个数） 改变时会触发
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			//currentPage（当前页数） 改变时会触发
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
			},
			// 新增弹窗提交成功回调
			handleClose() {
				this.showModel = false;
			},
			// 新增验证事件
			submitForm() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						// this.addSubmit()
					} else {
						return false
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.top-box {
		min-height: 488px;
	}

	.box-left {
		display: flex;
		flex-direction: column;

		.outline-imgs {
			width: 349px;
			height: 349px;

			&::v-deep img {
				width: 100%;
				height: 100%;
			}

			.outline-img {
				width: 325px;
				height: 325px;
				border: 1px solid #f2f2f2;
				padding: 10px;

				&::v-deep img {
					width: 100%;
					height: 100%;
				}
			}

			.outline-piclist {
				width: 307px;
				height: 80px;
				overflow: hidden;
				margin: 10px 0;
				position: relative;
				padding: 0 20px;

				.piclist-box {
					cursor: pointer;
					position: absolute;
					left: 20px;
					top: 0;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
				}

				.left-arrow {
					z-index: 3;
					width: 27px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					background-color: #333333;
					opacity: 0.1;
					font-size: 20px;
					color: #fff;
					position: absolute;
					left: 0;
					top: 0;
					cursor: pointer;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;

					&:hover {
						opacity: 0.7;
						transition-property: opacity;
						transition-duration: 0.3s;
					}
				}

				.right-arrow {
					z-index: 3;
					width: 27px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					background-color: #333333;
					opacity: 0.1;
					font-size: 20px;
					color: #fff;
					position: absolute;
					right: 0;
					top: 0;
					cursor: pointer;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;

					&:hover {
						opacity: 0.7;
						transition-property: opacity;
						transition-duration: 0.3s;
					}
				}

				.piclist-img {
					width: 78px;
					height: 78px;
					margin-right: 10px;
					border: 1px solid #f2f2f2;
					background-color: rgba(248, 249, 254, 1);

					img {
						width: 78px;
						height: 78px;
					}

					&:hover,
					&.active {
						border: 1px solid #f03333;
					}
				}
			}
		}
	}

	.tag-box {
		padding: 0 3px;
		background-image: linear-gradient(to right top, #FF0000, #FF9999);
	}

	.activity-box {
		width: 715px;
		height: 40px;
	}

	.box-right {}

	.commodityInformation-box {
		width: 715px;
	}

	.dashed {
		border-right: 1px dashed #FA2C60;
		margin: 0 2px;
	}

	.receive-box {
		border-bottom: 1px dashed #FA2C60;
	}

	.factory-box {
		width: 95px;
	}

	.factory-hover:hover {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.address-box {
		height: 50px;
	}

	.tatle-box {
		width: 52px;
	}

	.btn-box {
		width: 235px;
		height: 45px;
		line-height: 45px;
		box-sizing: border-box;
	}

	.b-box-left {
		width: 260px;
		height: 160px;
		box-sizing: border-box;
	}

	.img-box {
		width: 45px;
		height: 45px;
		box-sizing: border-box;
	}

	.img-style {
		width: 35px;
		height: 35px;
	}

	.shopStarRating-box {
		width: 150px;
		height: 22px;
		line-height: 22px;
		box-sizing: border-box;
	}

	.el-rate::v-deep {
		height: 15px;

		.el-rate__icon {
			margin-right: 0;
			font-size: 15px;
		}
	}

	.shopBtn-box {
		width: 110px;
		height: 30px;
		line-height: 30px;
		box-sizing: border-box;
	}

	.b-box-right {
		width: 1100px;
		min-height: 521px;
		// height: auto;
	}

	.img-box1 {
		width: 107px;
		height: 107px;
	}

	.img-style1 {
		width: 96px;
		height: 96px;
	}

	.title-box {
		width: 107px;
	}

	.el-tabs--border-card::v-deep {
		background: #FFF;
		border: none;
		box-shadow: none;

		.el-tabs__header {
			background-color: #F8F9FE;
			border: 1px solid #f2f2f2;
			margin: 0;
		}

		.el-tabs__item:hover {
			@include themify($themes) {
				color: themed('themes_color');
			}
		}

		.is-active {
			@include themify($themes) {
				color: themed('themes_color');
			}

			background-color: #FFF;
			border-right-color: #f2f2f2;
			border-left-color: #f2f2f2;
		}

		.el-tabs__content {
			padding: 10px 0;
		}
	}

	.evaluateTitle-box {
		height: 63px;
	}

	.img-box2 {
		width: 35px;
		height: 35px;
		border-radius: 50%;
	}

	.img-style2 {
		width: 35px;
		height: 35px;
		border-radius: 50%;
	}

	.whole {
		width: 65px;
		height: 24px;
		line-height: 24px;
	}

	.evaluate-btn {
		height: 24px;
		line-height: 24px;
	}

	.max-width1110 {
		max-width: 1100px;
		margin: 20px auto 0 auto;
	}

	::v-deep .el-input-number--mini {
		line-height: 29px;
	}

	::v-deep .el-input-number--mini .el-input-number__decrease {
		width: 31px;
	}

	::v-deep .el-breadcrumb__inner.is-link {
		font-weight: 400;
		color: #999999;
	}

	::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}
</style>
